/**
 * Main js file
*/
jQuery(document).ready(function ($) {
    'use strict';

    var headerThreshold = 962,
        previousTop = 0,
        $window = $(window),
        $form = $('form'),
        $navBar = $('.navbar'),
        $ellipsis = $('.ellipsis'),
        token = $('meta[name="csrf-token"]').attr('content');

    /**
     * Initialize header animation when window
     * width bigger than headerThreshold
     *
     * @return void
     */
    function initHeaderAnimation()
    {
        var currentTop,
            headerHeight = $navBar.outerHeight();

        if ($window.width() > headerThreshold) {
            currentTop = $window.scrollTop();

            // if user is scrolling up
            if (currentTop < previousTop) {
                if (currentTop > 0 && $navBar.hasClass('is-fixed')) {
                    $navBar.addClass('is-visible');
                } else {
                    $navBar.removeClass('is-visible is-fixed');
                }
                // if scrolling down...
            } else {
                $navBar.removeClass('is-visible');

                if (currentTop > headerHeight && !$navBar.hasClass('is-fixed')) {
                    $navBar.addClass('is-fixed');
                }
            }
            previousTop = currentTop;
        }
    }

    /**
     * Initialize dotdotdot plugin.
     *
     * @return void
     */
    function initEllipsis()
    {
        $ellipsis.dotdotdot({
            watch: true
        });
    }

    /**
     * On submit form.
     *
     * @return void
     */
    function onSubmitForm(event)
    {
        var $message = $form.find('.message'),
            $spinner = $form.find('.spinner'),
            url = $form.attr('action'),
            success = $form.data('success'),
            error = $form.data('error');

        event.preventDefault();

        $message.hide();
        $spinner.css('display', 'inline-block');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token
            }
        });

        $.post(url, $form.serialize())
            .done(function (data) {
                $spinner.hide();
                $message.show();
                if (data === 'true') {
                    $message.html(success);
                } else {
                    $message.html(error);
                }
            });
    }

    /**
     * Initialize method.
     *
     * @return void
     */
    function setEvents()
    {
        $window.on('scroll', initHeaderAnimation);
        $window.load(initEllipsis);

        $form.on('submit', onSubmitForm);
    }

    /**
     * Initialize method.
     *
     * @return void
     */
    function init()
    {
        setEvents();
    }

    // Initialize
    init();
});